export default `<xml xmlns="https://developers.google.com/blockly/xml">
<block type="s4d_login" id="A%a!wojyRt1!YF;XKAA^" x="176" y="13">
  <value name="TOKEN">
    <shadow type="text" id="Fy)OD6saz0ViTZ:|!ue)">
      <field name="TEXT">Your bot token</field>
    </shadow>
  </value>
</block>
<block type="on_real_button" id="JO9%(Eu9-RNY,i;9)HH!" x="179" y="115">
  <statement name="STATEMENTS">
    <block type="make_ahq_button" id="JI=6.ulq_9Fr+XtUT0tV">
      <value name="button name">
        <block type="text" id="FyV-$J401tKJVBNv]BU,">
          <field name="TEXT">pp</field>
        </block>
      </value>
      <statement name="STATEMENTS">
        <block type="style_ahq_button" id="}.o],T+02m/he(Y3%m$i">
          <field name="Label">SECONDARY</field>
          <value name="button name">
            <block type="text" id="}tny0KyEO5*y+J2\`\`oD!">
              <field name="TEXT">pp</field>
            </block>
          </value>
          <next>
            <block type="label_ahq_button" id="!IU(J:~iFOzP^@zHxBKi">
              <value name="Label">
                <block type="text" id="z3oK|QY2gZe#Gmb[9MV2">
                  <field name="TEXT">ppqq</field>
                </block>
              </value>
              <value name="button name">
                <block type="text" id="h722fkQW[p8!DjYEBMB|">
                  <field name="TEXT">pp</field>
                </block>
              </value>
              <next>
                <block type="emoji_ahq_button" id="1b{==uN6!MMc=\`S|)}4+">
                  <value name="Label">
                    <block type="text" id="Bvp5z]hXSXQLERDSewFo">
                      <field name="TEXT">🤖</field>
                    </block>
                  </value>
                  <value name="button name">
                    <block type="text" id="l(uz@41)1fLnw$XWsRe)">
                      <field name="TEXT">pp</field>
                    </block>
                  </value>
                  <next>
                    <block type="url_ahq_button" id="P6TVxewEd8oC_wod3)o=">
                      <value name="Label">
                        <block type="text" id="F#iF)ZfRSEUMZe{(zwt7">
                          <field name="TEXT">https://google.com</field>
                        </block>
                      </value>
                      <value name="button name">
                        <block type="text" id="FH14lF#.\`E1j6x$aSSv}">
                          <field name="TEXT">pp</field>
                        </block>
                      </value>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </next>
        </block>
      </statement>
      <next>
        <block type="make_ahq_button" id="nl+:Mv*Ee,\`)U*(tAOG#">
          <value name="button name">
            <block type="text" id="I(6wb4Fq@e/v(w8A|Ki@">
              <field name="TEXT">ahq</field>
            </block>
          </value>
          <statement name="STATEMENTS">
            <block type="style_ahq_button" id="u7X@zvl3V+?Ls5pe}0+O">
              <field name="Label">SECONDARY</field>
              <value name="button name">
                <block type="text" id="hm*hP)c{{m%/r=JOwdiZ">
                  <field name="TEXT">ahq</field>
                </block>
              </value>
              <next>
                <block type="label_ahq_button" id="xARpv5kap({_R;CpVr7M">
                  <value name="Label">
                    <block type="text" id="eDeS%!$vQ/.el3Rp($o1">
                      <field name="TEXT">ahq</field>
                    </block>
                  </value>
                  <value name="button name">
                    <block type="text" id="Y0i#bp;-err3:t]Z%?z@">
                      <field name="TEXT">ahq</field>
                    </block>
                  </value>
                  <next>
                    <block type="emoji_ahq_button" id="+Y@(A+-6%5Z4Gw3RbsV1">
                      <value name="Label">
                        <block type="text" id="p3K7tm#Q%%HBP[lWZ)*e">
                          <field name="TEXT">🤖</field>
                        </block>
                      </value>
                      <value name="button name">
                        <block type="text" id="a4Vu?QTY[f@bn5o#loz!">
                          <field name="TEXT">ahq</field>
                        </block>
                      </value>
                      <next>
                        <block type="url_ahq_button" id="TV8td7;dphbMmt*Bi.1w">
                          <value name="Label">
                            <block type="text" id="O\`;n8)%|P#%!}UO*0RN/">
                              <field name="TEXT">ahq</field>
                            </block>
                          </value>
                          <value name="button name">
                            <block type="text" id="]-hnXLg%~v{vQC)S+{(p">
                              <field name="TEXT">ahq</field>
                            </block>
                          </value>
                        </block>
                      </next>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </statement>
          <next>
            <block type="snd_ahq_button" id="9*\`7_=c0^jphSIEjOD7n">
              <value name="Label">
                <block type="s4d_message_channel" id="RgPZ,;?F(]F\`_sQ9,Ucr"></block>
              </value>
              <value name="button name">
                <block type="text" id="k+Q[24G1iyb1GzA)4][6">
                  <field name="TEXT">button test</field>
                </block>
              </value>
              <value name="button val">
                <block type="text" id="~*^MxY%K$a{P\`N_6b-X^">
                  <field name="TEXT">ahq, pp</field>
                </block>
              </value>
            </block>
          </next>
        </block>
      </next>
    </block>
  </statement>
</block>
</xml>`;